import React from 'react'
import Usage1 from '../../../public/home/Usage1.svg';
import Usage2 from '../../../public/home/Usage2.svg';
import Usage3 from '../../../public/home/Usage3.svg';
import Dots from '../../../public/home/dots.webp';
import Image from 'next/future/image'

const HomeUsage = () => {
  return (
    <>
    <div className="usage-container py-md-3 px-md-5 py-5 px-2">
        <div className="row g-0">
            <div className="col pt-md-5">
                <h2 className="usage-title text-center">
                    Not Just Another <span className='color'> Ticketing </span> Platform!
                </h2>
                <h3 className='sub-text text-center mx-auto'>
                    With KonfHub, you can gamify and amplify your events and make them a "Sell Out"
                </h3>
            </div>
        </div>
        <div className="row g-0">
            <div className="col-10 offset-1 text-center">
                <div className="row g-0 pb-md-5 d-flex">
                    <div className="col-md-4 col-12 usage-img-container">
                        <Image src={Usage1} className="usage-img w-75 h-auto" alt="Customizable event creation is easy with KonfHub" width={309} height={377}/>
                    </div>
                    <div className="col-md-4 col-12 py-3 py-md-0 usage-img-container">
                        <Image src={Usage2} className="usage-img-2 w-75 h-auto" alt="Referral contest for amplifying ticket sales" width={309} height={377}/>
                    </div>
                    <div className="col-md-4 col-12 py-3 py-md-0 usage-img-container">
                        <Image src={Usage3} className="usage-img w-75 h-auto" alt="Event gamification for audience engagement" width={309} height={377}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <style>{`
        .usage-container {
            background: #f6f9fc;
        }

        .usage-container .usage-title {
            color: #572148;
            font-weight: 800;
            font-size: 50px;
            font-family: "Nunito";
        }
        .color{
            color: #fb5850;
        }
        .sub-text{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: #4F4F4F;
        }

        .usage-container .usage-img-container {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            background: url(${Dots}) 100% 35% no-repeat;
        }

        .usage-container .usage-img-container .usage-img-2 {
            padding-top: 100px;
        }

        /* SM screens */
        @media only screen and (min-width: 480px) and (max-width: 768px) {
            .usage-container .usage-title {
                font-size: 35px;
            }
            .usage-container .usage-img-container .usage-img-2 {
            padding-top: 0px;
        }
        }

        /* XS screens */
        @media only screen and (max-width: 480px) {
            .usage-container .usage-title {
                font-size: 35px;
            }
            .usage-container .usage-img-container .usage-img-2 {
            padding-top: 0px;
        }
        }
    `}</style>
</>
  )
}

export default HomeUsage
